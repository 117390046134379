<template>
<div>
  <div>
    <div class="content-header container-fluid bg-white">
      <div class="row">
        <div class="col-sm-4">
          <h4><i class="ti-world"> </i> 通用管理 | 班级列表</h4>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="w-block" style="padding: 2px;">
            <el-select v-model="grid.sea.GRADE" @change="filter" clearable placeholder="请选择年级" >
              <el-option :label="it.name" :value="it.value" v-for="it in whale.grade.lst" :key="it.value" />
            </el-select>
            <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px" >
              <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
            </el-input>
          </div>
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1" @page-changed="loadPage">
            <el-table-column prop="ID" label="编号" width="60" align="center"/>
            <el-table-column prop="NAME" label="班级名称" width="200" align="center" />
            <el-table-column label="年级" width="80" align="center">
              <template slot-scope="scope">{{whale.grade.getName(scope.row.GRADE)}}</template>
            </el-table-column>
            <el-table-column />
          </wgrid>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Wgrid from "@/components/wgrid";
export default {
  name: "index",
  components: {Wgrid},
  data(){
    return{
      grid:{
        sea:{
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          ROLE:"",
          KEY:""
        },
        ls:[],
        total:0,
        loading:false,
      }
    }
  },
  created() {
    this.getList();
  },
  methods:{
    filter(){
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi){
      this.grid.sea.PAGE_INDEX = pi-1;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/School/ORG/ClassApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls=its;
          self.grid.total=n;
          self.grid.loading=false;
        }
      })
    },
  }
}
</script>

